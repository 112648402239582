import { getDefaultHeaders } from "./getRequestHeaders";

export const getLastUpdate = async (section) => {
    switch (section) {
        case "info":
            const response = await fetch("/api/user/getlastupdate", {
                method: "POST",
                headers: getDefaultHeaders(),
                body: JSON.stringify({
                    collection: "info"
                })
            })
            const json = await response.json()
            return json.last_update;
    }
}