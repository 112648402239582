const DAY_SECONDS = 86400
//const MONTH_SECONDS = 2629746
const DAYS_IN_MONTH = 31

export function DateToString(date: Date) {
    return new Intl.DateTimeFormat('hr-HR', { year: 'numeric', day: '2-digit', month: '2-digit' }).format(date)
}

export function DateToStringPrecise(date: Date) {
  return new Intl.DateTimeFormat('hr-HR', { 
    year: 'numeric', 
    day: '2-digit', 
    month: '2-digit', 
    hour: '2-digit', 
    minute: '2-digit' 
  }).format(date);
}

export function DaysSince(date: Date) {
    const now = new Date();
    const differenceInTime = now.getTime() - date.getTime();
    const differenceInDays = Math.floor(differenceInTime / (1000 * 60 * 60 * 24));
  
    if (differenceInDays === 0) {
      return 'Danas';
    } else if (differenceInDays === 1) {
      return 'Jučer';
    } else {
      return `Prije ${differenceInDays} dana`;
    }
  }

export function GetDateInMonths(months: number, start?: Date) {
    let Expiry;
    if (start) {
        Expiry = start
    } else {
        Expiry = new Date()
    }
    Expiry.setDate(Expiry.getDate() + months * DAYS_IN_MONTH);  // Adds months in days
    Expiry.setHours(23, 59, 59);  // Locks expiry to 23:59
    return Expiry
}