import {
  Box,
  Button,
  Group,
  Modal,
  TextInput,
  SimpleGrid,
  Text,
  Menu,
  Drawer,
  Title,
  Divider,
  Flex,
  Badge,
  Center,
  Alert,
  Select,
  HoverCard,
  ScrollArea,
  MultiSelect,
  Stack,
  Timeline,
  ThemeIcon,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  IconAlertCircle,
  IconCheck,
  IconCalendarPlus,
  IconExclamationCircle,
  IconExclamationMark,
  IconSnowflake,
  IconUser,
  IconCalendarDue,
  IconPlus,
} from "@tabler/icons";
import { useEffect, useState } from "react";

import ImageDisplayOrUpload from "./ImageDisplayOrUpload";
import { DatePicker, RangeCalendar } from "@mantine/dates";
import { useUpdateUser } from "../hooks/useUpdateUser";

import { FetchUserById } from "../hooks/MembersHook";
import { useAuthContext } from "../hooks/useAuthContext";
import { useDeleteUser } from "../hooks/useDeleteUser";
import { useFreezeUser } from "../hooks/useFreezeUser";
import { useThawUser } from "../hooks/useThawUser";
import MembershipExtensionModal from "../components/MembershipExtensionModal";

import { DateToString, DateToStringPrecise, DaysSince } from "../utils/dateManager";
import { useGetAllGroups } from "../hooks/useMemberGroups";
import MembershipSetManuallyModal from "./MembershipSetManuallyModal";

function ReturnImgSrc(image: any) {
  const base64 = window.btoa(
    new Uint8Array(image.data.data).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ""
    )
  );
  const imgSrc = `data:${image.contentType};base64,${base64}`;
  return imgSrc;
}

interface IUserEditModal {
  visible: boolean;
  callback: any; // Function that we call back when we change user details
  setEditVisible: any; // Function to set Visibility of Drawer
  _id: string; // _id of user in database
}

interface DisplayData {
  _id: string;
  name: string;
  surname: string;
  email: string;
  date_birth: string;
  date_due: string;
  date_joined: string; // Ne šalje se, al nek ostane
  date_lastseen: string;
  phone_number: string;
  membership_type: string;
  rfid: string;
  image: any;
}

interface FormValues {
  _id: string;
  name: string;
  surname: string;
  email: string;
  date_birth: Date;
  date_due: Date;
  phone_number: string;
  membership_type: string;
  rfid: string;
  image: any;
}

function UserEditModal({
  visible,
  setEditVisible,
  callback,
  _id,
}: IUserEditModal) {
  const { user } = useAuthContext();
  const { getAll } = useGetAllGroups();
  const [allGroups, setAllGroups] = useState([]);
  const [userGroups, setUserGroups] = useState<(string)[]>([]);
  const [data, setData] = useState<DisplayData | null>(null);
  const [duesPaid, setDuesPaid] = useState(false);
  const [isFrozen, setIsFrozen] = useState(false);
  const { updateUser } = useUpdateUser();
  const { deleteUser } = useDeleteUser();
  const { freezeUser } = useFreezeUser();
  const { thawUser } = useThawUser();
  const [extensionVisible, setExtensionVisible] = useState(false);
  const [manualVisible, setManualVisible] = useState(false);


  const userForm = useForm<FormValues>({
    initialValues: {
      _id: "",
      name: "displayedUser",
      surname: "",
      email: "",
      date_birth: new Date(),
      date_due: new Date(),
      phone_number: "",
      membership_type: "",
      rfid: "",
      image: "",
    },
  });

  useEffect(() => {
    if (visible) {
      getAll(true).then((data) => {
        var groups = data.map(
          (group: { _id: string; name: string; color: string }) => ({
            value: group._id,
            label: group.name,
            color: group.color,
          })
        );
        setAllGroups(groups);
      });

      FetchUserById(_id).then((data) => {
        userForm.setFieldValue("name", data.name.split(" ")[0]);
        userForm.setFieldValue(
          "surname",
          data.name.split(" ").slice(1).join(" ")
        );
        userForm.setFieldValue("email", data.email);
        userForm.setFieldValue("phone_number", data.phone_number);
        userForm.setFieldValue("date_birth", new Date(data.date_birth));
        userForm.setFieldValue("date_due", new Date(data.date_due));
        userForm.setFieldValue("membership_type", data.membership_type.type);
        userForm.setFieldValue("rfid", data.rfid);
        userForm.setFieldValue("_id", data._id);
        setUserGroups(data.groups)
        setData(data);
        //setDueDates([new Date(), data.date_due])
        setDuesPaid(Date.parse(data.date_due) > Date.now());
        setIsFrozen(data.frozen_status);
      });
    }
  }, [visible, _id]);

  const handleSubmit = async (values: any) => {
    const full_name = values.name + " " + values.surname;
    const props = {
      _id: values._id,
      name: full_name,
      email: values.email,
      date_birth: values.date_birth,
      date_due: values.date_due,
      phone_number: values.phone_number,
      image: values.image,
      membership_type: values.membership_type,
      rfid: values.rfid,
      groups: userGroups,
    };
    if ((await updateUser(props)) === true) {
      callback();
      setEditVisible(false);
    }
  };

  function setImageValue(files: any) {
    userForm.setFieldValue("image", files[0]);
  }

  const handleDelete = async () => {
    if (data) {
      await deleteUser(data._id);
      callback();
      setEditVisible(false);
    }
  };

  const handleFreeze = async () => {
    if (data) {
      if (isFrozen) {
        await thawUser(data._id);
      } else if (isFrozen == false) {
        await freezeUser(data._id);
      }
      callback();
      setEditVisible(false);
    }
  };

  const setMainModalVisible = async (visible: boolean) => {
    setEditVisible(visible)
    callback()
  }

  return data ? (
    // TODO: ADJUST FOR VIEWPORT (WILL NOT SHOW BUTTONS ON LAPTOPS)
    <>
      <Drawer
        opened={visible}
        onClose={() => {
          setEditVisible(false);
        }}
        padding="xl"
        size="50vw"
        position="right"
        transition="slide-left"
        transitionDuration={250}
        transitionTimingFunction="ease"
      >
        <ScrollArea.Autosize
          maxHeight="90vh"
          sx={{ maxWidth: "50vw" }}
          mx="auto"
          onPointerEnterCapture={() => { }}
          onPointerLeaveCapture={() => { }}
          placeholder={""}
        >
          <Box sx={{ maxWidth: 600 }} mx="auto">
            <Center mb="md">                <Title>{data.name}</Title>          </Center>
            <Flex
              mih={50}
              gap="50px"
              justify="flex-start"
              align="center"
              direction="row"
              wrap="wrap"
              mb="xl"
            >
              <div>
                <Alert color={isFrozen ? "cyan" : duesPaid ? "green" : "red"} radius="lg" variant="outline">
                  <ImageDisplayOrUpload
                    src={ReturnImgSrc(data.image)}
                    successCallback={setImageValue}
                  />
                </Alert>
              </div>
              <div>
                <Timeline active={0} bulletSize={24} lineWidth={2}>
                  <Timeline.Item bullet={<IconUser size={12} />} title="Član od">
                    <Text color="dimmed" size="sm">{DateToString(new Date(Date.parse(data.date_joined)))}</Text>
                  </Timeline.Item>

                  <Timeline.Item bullet={<IconCalendarDue size={12} />} title="Zadnji put viđen">
                    <Text color="dimmed" size="sm">{DateToString(new Date(Date.parse(data.date_lastseen)))}</Text>
                    <Text size="xs" mt={4}>{DaysSince(new Date(Date.parse(data.date_lastseen)))}</Text>
                  </Timeline.Item>

                  <Timeline.Item title="Status članarine" bullet={<ThemeIcon radius="xl" color={isFrozen ? "cyan" : duesPaid ? "green" : "red"}><IconCalendarDue size={12} /></ThemeIcon>} lineVariant="dashed">
                    <Text color="dimmed" size="sm"></Text>
                    <Text size="xs" mt={4}>{
                      isFrozen
                        ? "Članarina zamrznuta"
                        : duesPaid
                          ? "Članarina vrijedi"
                          : "Članarina istekla"}</Text>
                  </Timeline.Item>

                </Timeline>
                {/* <Stack>
              <Badge size="xl" radius="md" variant="gradient" gradient={{ from: 'indigo', to: 'cyan' }}>
                Član od:{" "}
              </Badge>
              <Badge size="xl" radius="md" variant="gradient" gradient={{ from: 'indigo', to: 'cyan' }}>
                Zadnji put viđen:{" "}
                
              </Badge>
              <Text italic size="xs" color={"grey"}>
                  Zadnji put viđen
                </Text>
              </Stack>
              <Center>
              </Center>
              <Center>
                <Text
                  italic
                  size="xs"
                  
                >
                  
                  !
                </Text>
              </Center> */}
              </div>
            </Flex>
          </Box>
          <Box sx={{ maxWidth: 600 }} mx="auto">
            <form
              onSubmit={userForm.onSubmit((values) => handleSubmit(values))}
            >
              <Divider my="xs" label="Osnovni podatci" labelPosition="center" />
              <SimpleGrid cols={2} spacing="md">
                <TextInput
                  withAsterisk
                  label="Ime"
                  placeholder="Pero"
                  {...userForm.getInputProps("name")}
                />

                <TextInput
                  withAsterisk
                  label="Prezime"
                  placeholder="Perić"
                  {...userForm.getInputProps("surname")}
                />
              </SimpleGrid>

              <TextInput
                withAsterisk
                label="Email"
                placeholder="imeprezime@email.com"
                {...userForm.getInputProps("email")}
              />

              <TextInput
                withAsterisk
                label="Broj telefona"
                placeholder="+385"
                {...userForm.getInputProps("phone_number")}
              />

              <DatePicker
                withAsterisk
                placeholder="Odaberi datum"
                label="Datum rođenja"
                inputFormat="DD/MM/YYYY"
                labelFormat="MM/YYYY"
                defaultValue={new Date()}
                {...userForm.getInputProps("date_birth")}
                mb="lg"
              />

              <Divider mt="lg" mb="lg" label="Grupe" labelPosition="center" />
              <MultiSelect data={allGroups} defaultValue={userGroups} limit={3} onChange={setUserGroups} />

              <Divider mt="lg" mb="lg" label="Članstvo " labelPosition="center" />
              <Select
                withAsterisk
                label="Vrsta članstva"
                // value={userForm.values.membership_type}
                defaultValue={userForm.values.membership_type} // ne radi [https://v5.mantine.dev/core/select/#read-only] (pogledaj za implementaciju)
                data={[
                  { value: "2xtjedno", label: "2x tjedno" },
                  { value: "3xtjedno", label: "3x tjedno" },
                  { value: "neograniceno", label: "neograničeno" },
                  { value: "jutarnja", label: "jutarnja (do 14h)" },
                ]}
                {...userForm.getInputProps("membership_type")}
              // onChange={(value) => {
              //   if (value !== null) {
              //     userForm.setFieldValue("membership_type", value);
              //   }
              // }}
              />
              <Text>Rok članarine</Text>
              <Menu trigger="hover" openDelay={100} closeDelay={400}>
                <Menu.Target>
                  <Badge
                    color={isFrozen ? "cyan" : duesPaid ? "green" : "red"}
                    radius="xl"
                    size="lg"
                    variant="outline"
                  >
                    {isFrozen
                      ? "Zamrznuto"
                      : DateToStringPrecise(new Date(data.date_due))}
                  </Badge>
                </Menu.Target>

                <Menu.Dropdown>
                  <Menu.Label>Upravljanje članarinom</Menu.Label>
                  {!isFrozen ? (
                    <Menu.Item
                      color="blue"
                      icon={<IconPlus size={25} />}
                      onClick={() => setExtensionVisible(true)}
                    >
                      Produži članstvo
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                  {!isFrozen ? (
                    <Menu.Item
                      color="green"
                      icon={<IconCalendarPlus size={25} />}
                      onClick={() => setManualVisible(true)}
                    >
                      Ručno namještanje
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                  {(duesPaid || isFrozen) ? (<Menu.Item
                    color={isFrozen ? "yellow" : "cyan"}
                    onClick={handleFreeze}
                    icon={<IconSnowflake size={25} />}
                  >
                    {isFrozen ? "Odmrzni članstvo" : "Zamrzni članstvo"}
                  </Menu.Item> ) : ("")}
                </Menu.Dropdown>
              </Menu>

              <TextInput
                withAsterisk
                label="RFID Oznaka Kartice"
                placeholder=""
                mt="xs"
                {...userForm.getInputProps("rfid")}
              />

              <SimpleGrid mb="10%" mt="50px" cols={2} spacing="xl">
                <Button type="submit">Spremi podatke</Button>
                <HoverCard width={280} shadow="md">
                  <HoverCard.Target>
                    <Button
                      color="red"
                      onClick={handleDelete}
                      leftIcon={<IconExclamationCircle />}
                    >
                      Obriši korisnika
                    </Button>
                  </HoverCard.Target>
                  <HoverCard.Dropdown>
                    <Text size="sm">
                      Jeste li sigurni da želite obrisati korisnika? Ovaj
                      postupak je nepovratan.
                    </Text>
                  </HoverCard.Dropdown>
                </HoverCard>
              </SimpleGrid>
            </form>

            <MembershipExtensionModal
              visible={extensionVisible}
              setExtensionVisible={setExtensionVisible}
              data={data}
              duesPaid={duesPaid}
              setMainModalVisible={setMainModalVisible}
            />
            <MembershipSetManuallyModal
              visible={manualVisible}
              setManualVisible={setManualVisible}
              data={data}
              setMainModalVisible={setMainModalVisible}
            />
          </Box>
        </ScrollArea.Autosize>
      </Drawer>
    </>
  ) : null;
}

export default UserEditModal;
