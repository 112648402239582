import {
  Box,
  Button,
  Group,
  Modal,
  TextInput,
  SimpleGrid,
  Text,
  NumberInput,
  Dialog,
  Select,
  MultiSelect,
  Stack,
  Divider,
  Stepper,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconCamera, IconCameraSelfie, IconUser } from "@tabler/icons";
import React, { useRef, useEffect, useState } from "react";

import ImageDisplayOrUpload from "./ImageDisplayOrUpload";
import { useRegister } from "../hooks/useRegister";
import { DatePicker } from "@mantine/dates";
import { GetDateInMonths, DateToString, DateToStringPrecise } from "../utils/dateManager";
import { useGetAllGroups } from "../hooks/useMemberGroups";
import { createAccounts } from "../hooks/test/createTestUsers";
// import { createAccounts } from "../hooks/test/createTestUsers";

interface FormValues {
  name: string;
  surname: string;
  email: string;
  date_birth: Date;
  // date_due: Date, Set by backend,
  // date_joined: Date, Set by backend
  phone_number: string;
  image: any;
  membership_type: string;
  membership_months: number;
}

function CreateUserModal({ callback }: any) {
  const [opened, setOpened] = useState(false);
  const [date, setDate] = useState("");
  const { register } = useRegister();
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [turned, setTurned] = useState(false);
  const [capturedFile, setCapturedFile] = useState<File | null>(null);
  const [weeklyChoice, setWeeklyChoice] = useState(Object);

  const [activeStep, setActiveStep] = useState(0);
  const nextStep = () =>
    setActiveStep((current) => (current < 3 ? current + 1 : current));
  const prevStep = () =>
    setActiveStep((current) => (current > 0 ? current - 1 : current));

  const [selectedGroups, setSelectedGroups] = useState<(string | undefined)[]>([]);
  const [allGroups, setAllGroups] = useState([]);
  const { getAll } = useGetAllGroups();

  useEffect(() => {
    getAll(true).then((data) => {
      var groups = data.map(
        (group: { _id: string; name: string; color: string }) => ({
          value: group._id,
          label: group.name,
          color: group.color,
        })
      );
      setAllGroups(groups);
    });
  }, []);

  useEffect(() => {
    async function getCameraStream() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      } catch (err) {
        console.error("Error accessing camera: ", err);
      }
    }

    if (turned) {
      getCameraStream();
    } else {
      stopCamera();
    }
    return () => {
      stopCamera();
    };
  }, [turned]);

  const stopCamera = () => {
    console.log("Stopping camera");
    if (videoRef.current && videoRef.current.srcObject) {
      console.log("Stopping camera stream");
      const stream = videoRef.current.srcObject as MediaStream;
      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => {
          console.log(`Stopping track: ${track.kind}`);
          track.stop();
        });
      }
      videoRef.current.srcObject = null;
    } else {
      console.log("No camera stream to stop");
    }
  };

  const capturePicture = () => {
    if (videoRef.current && canvasRef.current) {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      if (context) {
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;
        context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
        canvas.toBlob((blob) => {
          if (blob) {
            const file = new File([blob], "captured.png", {
              type: "image/png",
            });

            setCapturedFile(file);
            userForm.setFieldValue("image", file); // Save the captured file to the form
            setTurned(false); // Turn off the camera after capturing
            stopCamera(); // Stop the camera stream
          }
        }, "image/png");
      }
    }
  };

  const userForm = useForm<FormValues>({
    initialValues: {
      name: "",
      surname: "",
      email: "",
      date_birth: new Date(),
      phone_number: "",
      image: "",
      membership_type: "",
      membership_months: 1,
    },
  });

  const handleSubmit = async (values: any) => {
    const full_name = values.name + " " + values.surname;

    if (
      (await register(
        full_name,
        values.email,
        values.date_birth,
        values.phone_number,
        values.image,
        weeklyChoice,
        values.membership_months,
        selectedGroups
      )) === true
    ) {
      setOpened(false);
      setTurned(false);
      setCapturedFile(null);
      userForm.reset();
      callback();
    }
  };

  function setImageValue(files: any) {
    userForm.setFieldValue("image", files[0]);
  }

  const handleMonthChange = (months: number) => {
    let membership_due = GetDateInMonths(months);
    let membership_due_string = DateToStringPrecise(membership_due);
    setDate(`Članstvo do ${membership_due_string}`);
    userForm.setFieldValue("membership_months", months);
  };

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title="Izrada korisnika"
        size="50vw"
      >
        <Stepper
          active={activeStep}
          onStepClick={setActiveStep}
          breakpoint="sm"
        >
          <Stepper.Step
            label="Fotografija"
            description="Dodajte fotografiju korisnika"
          >
            <Title mt="md" size={24}>
              Fotografija
            </Title>
            <Text mb="lg" c="dimmed" size={14}>
              Prenesite fotografiju s računala ili uslikajte web-kamerom!
            </Text>
            {turned ? (
              <>
                <video
                  ref={videoRef}
                  autoPlay
                  style={{ width: "100%", height: "auto" }}
                />
                <Button
                  leftIcon={<IconCameraSelfie />}
                  onClick={capturePicture}
                  style={{ width: "100%", marginTop: "10px" }}
                  color="red"
                >
                  Uslikaj sliku
                </Button>
              </>
            ) : (
              <ImageDisplayOrUpload
                src={
                  capturedFile
                    ? URL.createObjectURL(capturedFile)
                    : "/img/user_default.png"
                }
                successCallback={setImageValue}
              />
            )}
            <Button
              style={{ width: "100%", marginTop: "10px" }}
              leftIcon={<IconCamera />}
              onClick={() =>
                turned == true
                  ? (stopCamera(), setTurned(!turned))
                  : setTurned(!turned)
              }
            >
              {turned ? "Ugasi" : "Upali"} kameru
            </Button>
            <canvas ref={canvasRef} style={{ display: "none" }} />
          </Stepper.Step>
          <Stepper.Step
            label="Osnovni podatci"
            description="Ispunite osnovne podatke"
          >
            <form
              onSubmit={userForm.onSubmit((values) => handleSubmit(values))}
            >
              <Stack spacing="md">
                <Title mt="lg" size={24}>
                  Osnovni podatci
                </Title>
                <SimpleGrid cols={2} spacing="md">
                  <TextInput
                    withAsterisk
                    label="Ime"
                    placeholder="Pero"
                    {...userForm.getInputProps("name")}
                  />

                  <TextInput
                    withAsterisk
                    label="Prezime"
                    placeholder="Perić"
                    {...userForm.getInputProps("surname")}
                  />
                </SimpleGrid>

                <TextInput
                  withAsterisk
                  label="Email"
                  placeholder="imeprezime@email.com"
                  {...userForm.getInputProps("email")}
                />

                <TextInput
                  withAsterisk
                  label="Broj telefona"
                  placeholder="+385"
                  {...userForm.getInputProps("phone_number")}
                />

                <DatePicker
                  withAsterisk
                  placeholder="Odaberi datum"
                  label="Datum rođenja"
                  inputFormat="DD/MM/YYYY"
                  labelFormat="MM/YYYY"
                  defaultValue={new Date()}
                  {...userForm.getInputProps("date_birth")}
                />
              </Stack>
            </form>
          </Stepper.Step>
          <Stepper.Step
            label="Članstvo"
            description="Potvrdite detalje članstva"
          >
            <form
              onSubmit={userForm.onSubmit((values) => handleSubmit(values))}
            >
              <Stack>
                <Group position="center" spacing="md" grow>
                  <Stack>
                    <Title mt="lg" size={24}>
                      Članstvo
                    </Title>
                    <Select
                      withAsterisk
                      label="Vrsta članstva"
                      data={[
                        { value: "2xtjedno", label: "2x tjedno" },
                        { value: "3xtjedno", label: "3x tjedno" },
                        { value: "neograniceno", label: "neograničeno" },
                        { value: "jutarnja", label: "jutarnja (do 14h)" },
                      ]}
                      onChange={(value) => setWeeklyChoice(value)}
                    />
                    <NumberInput
                      withAsterisk
                      min={1}
                      max={12}
                      description={date}
                      label="Učlani na (broj mjeseci)"
                      placeholder="Broj mjeseci"
                      onChange={handleMonthChange}
                      // {...userForm.getInputProps('membership_months')}
                    />
                    <MultiSelect
                      label={"Dodavanje u grupe"}
                      data={allGroups}
                      mb="lg"
                      maxSelectedValues={3}
                      onChange={setSelectedGroups}
                    />
                  </Stack>
                </Group>
                <Group position="center" mt="md">
                  <Button type="submit" color="green">
                    Izradi korisnika
                  </Button>
                </Group>
              </Stack>
            </form>
          </Stepper.Step>
        </Stepper>

        {/*<Box mx="auto">
          
        </Box>*/}
        <Group position="center" mt="xl">
          {activeStep > 0 ? (
            <Button variant="default" onClick={prevStep}>
              Nazad
            </Button>
          ) : null}
          {activeStep < 2 ? <Button onClick={nextStep}>Dalje</Button> : null}
        </Group>
      </Modal>

      <Dialog opened={!opened} size="auto" radius="md">
        <Button
          //onClick={createAccounts}
          onClick={() => setOpened(true)}
          leftIcon={<IconUser />}
          radius="xl"
          color="green"
        >
          Izrada korisnika
        </Button>
      </Dialog>
    </>
  );
}

export default CreateUserModal;
