export default function formatMinutes(minutes) {
    let formattedMinutes = minutes;
    if (minutes < 10) {
      formattedMinutes = "0" + minutes;
    }
    return formattedMinutes;
  }
  
  // const time = new Date();
  // const hours = time.getHours();
  // const minutes = formatMinutes(time.getMinutes());
  
  // console.log(`${hours}:${minutes}`);