import { createContext, useReducer, useEffect } from 'react'
import jwtDecode from "jwt-decode"
import { showWarning } from '../utils/showPopup';

export const AuthContext = createContext()

function hasTokenExpired(authToken) {
    try {
        const currentTime = Date.now() / 1000;
        const decodedToken = jwtDecode(authToken);
        return decodedToken.exp < currentTime;
    } catch (error) {
        return true;
    }
}

function isTokenAboutToExpire(authToken, seconds) {
    try {
        const currentTime = Date.now() / 1000;
        const decodedToken = jwtDecode(authToken);
        return decodedToken.exp - currentTime <= seconds;
    } catch (error) {
        return true;
    }
}

export const authReducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN':
            console.log("Logging in as", action.payload);
            return { user: action.payload };
        case 'LOGOUT':
            console.log("Logging user out!");
            return { user: null };
        default:
            return state;
    }
}

export const AuthContextProvider = ({ children }) => {
    const [state, setContextState] = useReducer(authReducer, {
        user: null
    });

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            if (hasTokenExpired(user.token)) {
                setContextState({ type: 'LOGOUT', payload: user });
            } else {
                setContextState({ type: 'LOGIN', payload: user });
            }
        }
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (state.user && isTokenAboutToExpire(state.user.token, 30)) {
                showWarning("30 sekundi do isteka sesije, uskoro ćete biti odjavljeni. Molimo spremite promjene.")
            }
            if (state.user && hasTokenExpired(state.user.token)) {
                setContextState({ type: 'LOGOUT', payload: state.user });
                showWarning("Sesija je istekla - odjavljeni ste.")
            }
        }, 30*1000); // Check every 30 seconds

        return () => {console.log("clearing interval"); clearInterval(interval);} // Cleanup on unmount
    }, [state.user]);

    console.log('AuthContext state:', state);

    return (
        <AuthContext.Provider value={{ ...state, setContextState }}>
            {children}
        </AuthContext.Provider>
    )
}